<!--
服务订单
-->
<template>
  <div class="mainBox">
    <div class="shipTitle">
      <div class="changTitle">
        <div
          class="changeItme"
          :class="changeIndex == 1 ? 'changeItmeActive' : ''"
          @click="changeIndex = 1"
        >
          设备数据
        </div>
      </div>

      <!-- 设备编号 -->
      <div class="d_No">设备编号：D0121611200010</div>
    </div>
    <!---->
    <div class="shipMain">
      <!-- 视频播放 -->
      <div class="videoBox" v-if="showHasCamera" @click="getVedioToken()">
        <img src="@/assets/img/lease/video.png" alt="" class="videoIcon" />
        <div class="videoTxt">视频监控</div>
      </div>

      <div class="shipNav">
        <img
          src="@/assets/img/homeGuardianship/pressTitle.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">设备数据</span>
      </div>
      <!-- 设备数据列表 -->
      <div class="deviceList">
        <div class="deviceItem devicebg1">
          <div class="deviceItemLeft">
            <p class="leftTitle">平均心率</p>
            <p class="leftCount">
              <!-- <span class="countNum">{{mattressMsg}}</span> -->
              <span class="countUnit">{{ mattressMsg.HeartRate }}</span>
            </p>
          </div>
          <div class="deviceItemRight">
            <img
              src="@/assets/img/homeGuardianship/de_heart.png"
              alt=""
              class="deviceIcon"
            />
          </div>
        </div>

        <div class="deviceItem devicebg2">
          <div class="deviceItemLeft leftColor">
            <p class="leftTitle">平均呼吸率</p>
            <p class="leftCount">
              <span class="countUnit">{{ mattressMsg.BreatheRate }}</span>
            </p>
          </div>
          <div class="deviceItemRight">
            <img
              src="@/assets/img/homeGuardianship/de_breathe.png"
              alt=""
              class="deviceIcon"
            />
          </div>
        </div>
        <div class="deviceItem devicebg3">
          <div class="deviceItemLeft leftColor2">
            <p class="leftTitle">在床状态</p>
            <p class="leftCount">
              <span class="countUnit">{{ mattressMsg.BedStatus }}</span>
            </p>
          </div>
          <div class="deviceItemRight">
            <img
              src="@/assets/img/homeGuardianship/de_move.png"
              alt=""
              class="deviceIcon"
            />
          </div>
        </div>
      </div>

      <div class="deviceList">
        <div class="deviceItem devicebg4">
          <div class="deviceItemLeft leftColor3">
            <p class="leftTitle">室温</p>
            <p class="leftCount">
              <span class="countUnit">{{ mattressMsg.Temperature }}</span>
            </p>
          </div>
          <div class="deviceItemRight">
            <img
              src="@/assets/img/homeGuardianship/temperature.png"
              alt=""
              class="deviceIcon"
            />
          </div>
        </div>

        <div class="deviceItem devicebg5">
          <div class="deviceItemLeft leftColor4">
            <p class="leftTitle">呼吸稳定度</p>
            <p class="leftCount">
              <span class="countUnit">
                {{ mattressMsg.BreatheRateStableTxt }}
              </span>
            </p>
          </div>
          <div class="deviceItemRight">
            <img
              src="@/assets/img/homeGuardianship/Respiratorystability.png"
              alt=""
              class="deviceIcon"
            />
          </div>
        </div>
      </div>
      <!-- 预警 -->
      <div class="shipNav navMargin">
        <img
          src="@/assets/img/homeGuardianship/icon_state.png"
          alt=""
          class="titleIcon"
        />
        <span class="titleName">预警信息</span>
      </div>
      <!-- table -->
      <div class="tableBox">
        <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="createTime" label="预警时间">
          </el-table-column>
          <el-table-column prop="kind" label="预警类型"> </el-table-column>
          <el-table-column prop="detail" label="预警内容"> </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 视频播放 -->

    <el-dialog title="" :visible.sync="showCamera" width="1000px">
      <div class="cameraBox" id="video-container"></div>
    </el-dialog>
  </div>
</template>
<script>
import {
  GetEquipmentVedioToken,
  GetNurseBedAlarm,
} from "../../api/homeGuardianship";
import EZUIKit from "ezuikit-js";
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      timeData: "",
      changeIndex: 1,
      userInfo: {},
      path: "wss://socket.deebcg.cn/", //WebSocket地址
      mattressMsg: {},
      showHasCamera: false,
      showCamera: false,
      tableData: [],
      loading: false,
    };
  },
  created() {
    //this.getVedioToken();
    // this.initSocket();
  },

  mounted: function () {
    this.initSocket();
    if (this.mattressInfo.equipmentID) {
      this.getNurseBedAlarm(this.mattressInfo.id);
    }

    if (this.cameraInfo.id && this.cameraInfo.isSetup == 1) {
      this.showHasCamera = true;
    }
  },
  destroyed: function () {
    this.close();
  },
  computed: {
    ...mapState(["mattressInfo", "cameraInfo"]),
  },
  watch: {
    mattressInfo: {
      handler(newVal) {
        if (newVal.equipmentNo) {
          this.send();
          this.getNurseBedAlarm(newVal.id);
        }
      },
    },
    cameraInfo: {
      handler(newVal) {
        console.log(newVal);
        if (newVal.id && newVal.isSetup == 1) {
          this.showHasCamera = true;
        }
      },
    },
  },
  methods: {
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 === 1) {
        return "double-row";
      }
      return "";
    },
    getNurseBedAlarm(id) {
      this.loading = true;
      GetNurseBedAlarm(id).then((res) => {
        this.loading = false;
        let data = res.result;
        this.tableData = data;
      });
    },

    getVedioToken() {
      this.showCamera = true;
      GetEquipmentVedioToken(this.cameraInfo.id).then((res) => {
        console.log(res);
        this.palyVideo(res.result.token, res.result.playUrl);
      });
    },
    palyVideo(accessToken, url) {
      new EZUIKit.EZUIKitPlayer({
        id: "video-container", // 视频容器ID
        accessToken: accessToken,
        url: url,
      });
    },

    initSocket() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        this.socket = new WebSocket(this.path);
        // 监听socket连接
        this.socket.onopen = this.open;
        // 监听socket错误信息
        this.socket.onerror = this.error;
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
        // setTimeout(() => {
        //   this.send();
        // }, 1000);
      }
    },
    open: function () {
      console.log("socket连接成功");
      this.send();
    },
    error: function () {
      console.log("连接错误");
    },
    getMessage: function (msg) {
      let data = JSON.parse(msg.data);
      if (data.MsgType == "DeviceInfo") {
        this.mattressMsg = data;

        this.mattressMsg.BreatheRateStableTxt = this.setBreatheRateStable(
          this.mattressMsg.BreatheRateStable
        );
      }
    },

    //解析BreatheRateStable
    setBreatheRateStable(val) {
      let num = Number(val);
      let text = "--";
      if (num >= 0 && num <= 9) {
        text = "很平稳";
      }
      if (num >= 10 && num <= 14) {
        text = "平稳";
      }
      if (num >= 15 && num <= 24) {
        text = "一般";
      }
      if (num >= 25 && num < 35) {
        text = "不平稳";
      }
      if (num >= 35) {
        text = "非常不平稳";
      }
      return text;
    },

    // 发送消息给被连接的服务端
    send() {
      console.log(this.mattressInfo);
      let params = { type: "DeviceInfo", mac: this.mattressInfo.equipmentNo };

      console.log("发送消息", params);
      this.socket.send(JSON.stringify(params));
    },
    close: function () {
      console.log("socket已经关闭");
      this.socket.close();
    },
  },
};
</script>
<style lang="less" scoped>
.shipMain {
  position: relative;
}
.videoBox {
  background: url(../../assets/img/lease/leftBg.png) no-repeat;
  background-size: contain;
  width: 174px;
  height: 54px;
  position: absolute;
  top: 97px;
  right: -10px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  cursor: pointer;
}
.videoIcon {
  width: 24px;
  height: 24px;
}
.videoTxt {
  font-size: 24px;
  font-weight: 400;
  color: #0051ff;
  line-height: 24px;
  margin-left: 10px;
}
.col-content {
  display: flex;
  align-items: center;
}
.progressFirst {
  margin-top: 20px;
}
.colLable {
  font-size: 12px;
  font-weight: 400;
  color: #2f69e6;
  flex: 1;
  text-align: right;
}
.colValue {
  width: 292px;
  min-height: 38px;
  background: rgba(47, 105, 230, 0.05);
  border-radius: 5px 5px 5px 5px;
  padding: 13px 15px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
.proRow {
  margin-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  .el-col {
    margin: 10px 0;
  }
}
.d_No {
  height: 14px;
  font-size: 14px;
  font-weight: 400;
  color: #2f69e6;
}

.deviceList {
  display: flex;
  margin-top: 20px;
}
.deviceItem {
  width: 251px;
  height: 110px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 30px;
}
.devicebg1 {
  background: url(../../assets/img/homeGuardianship/bg_device.png) no-repeat;
  background-size: contain;
}
.devicebg2 {
  background: url(../../assets/img/homeGuardianship/bg_device2.png) no-repeat;
  background-size: contain;
}
.devicebg3 {
  background: url(../../assets/img/homeGuardianship/bg_device3.png) no-repeat;
  background-size: contain;
}
.devicebg4 {
  background: url(../../assets/img/homeGuardianship/4.png) no-repeat;
  background-size: contain;
}
.devicebg5 {
  background: url(../../assets/img/homeGuardianship/5.png) no-repeat;
  background-size: contain;
}
.deviceIcon {
  width: 64px;
  height: 64px;
}
.deviceIcon2 {
  width: 46px;
  height: 46px;
}
.leftTitle {
  font-size: 20px;
  font-weight: 400;
  color: #f83e04;
  margin-bottom: 10px;
}
.countNum {
  font-size: 31px;
  color: #f83e04;
  margin-right: 5px;
}
.countUnit {
  font-size: 18px;
  font-weight: 400;
  color: #f83e04;
}

.leftColor p {
  color: #3e7a72 !important;
}
.leftColor span {
  color: #3e7a72 !important;
}

.leftColor2 p {
  color: #0e4eea !important;
}
.leftColor2 span {
  color: #0e4eea !important;
}
.leftColor3 p {
  color: #ff219c !important;
}
.leftColor3 span {
  color: #ff219c !important;
}
.leftColor4 p {
  color: #3e7a72 !important;
}
.leftColor4 span {
  color: #3e7a72 !important;
}
</style>
